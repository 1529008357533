.box-filter {
    border-top: 1px solid rgb(219, 219, 219);
    font-size: 0.88rem;
    padding-bottom: 1.88rem;
}

.box-filter-title {
    padding-top: 1.88rem;
    color: rgb(0, 0, 0);
    font-weight: bold;
}

.sort-filter-container {
    width: 100%;
    margin: 1.88rem auto;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.sort-filter-container>.sort-filter {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 15rem;
}

.pagination-market {
    display: flex;
    width: 100%;
    height: 58px;
    padding-right: 29px;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
}