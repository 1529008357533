/* Detail Page */
.content-detail {
     background-color: #fff;
     padding: 25px;
     margin-bottom: 20px;
}
.img-detail {
     width: 100%;
     padding-right: 40px;
     /* max-height: 20rem; */
}
.info-standard {
     height: auto;
}
.info-top{
    height: 50%;
}
.info-below {
     height: 50%;
     padding-left: 15px;
}
.row-below {
    margin-left: 15px;
    margin-bottom: -10px;
}
.buton-below{
    width: 250px;
}
.dropdown-div{
    margin-left: 30px;
}
.price{
    color: rgb(255, 103, 0);
}
