.sidebar-container {
    position: fixed;
    z-index: 2;
    height: 100vh;
    width: 18rem;
    background-image: linear-gradient(to bottom right, #fb5c40, #ec4899);
    border-radius: 4px;
    left: -18rem;
    transition: 0.5s;
    .sidebar-wrapper {
        position: relative;
        padding: 1rem;
        height: 100vh;
        .sidebar-content {
            .__header {
                padding-left: 1rem;
            }
            .__content {
                margin-top: 1rem;
                border-top: 2px solid #fff;

                ul {
                    margin-top: 1rem;
                }

                li {
                    width: 100%;
                    padding: 1rem 1rem 0.5rem 1rem;
                    color: #fff;

                    .__inline {
                        float: left;
                    }
                }
                a {
                    display: flex;
                    align-items: center;
                    &:hover {
                        color: #000;
                        text-shadow: 0 0 10px #fff;
                    }
                }
                svg {
                    margin-right: 0.5rem;
                }
            }
        }

        .sidebar-arrow {
            position: absolute;
            top: 50%;
            right: 1rem;
            cursor: pointer;
            background-color: burlywood;
            width: 1.75rem;
            height: 6rem;
            display: flex;
            align-items: center;
            text-align: center;
            border-radius: 8px;
            background-image: linear-gradient(to bottom right, #f71d00, #ec4899);
            border: 1px solid #ff02dd;
            color: #fff;

            svg {
                width: 100%;
                height: fit-content;
            }

            &:hover {
                background-image: linear-gradient(to bottom right, #ec4899, #f71d00);
            }
        }
    }
}
